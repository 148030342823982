<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :top_label="false"
                            :name_items="'Archived '+NAME_ITEMS"
                            :name_item="'Archived '+NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="DataItems"   
                            :is_joined="true" 
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "archive_"+DATA.ITEMS.ITEMS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.ITEMS.names,
                NAME_ITEM:DATA.ITEMS.ITEMS.name,
                VALUE_ITEMS:DATA.ITEMS.ITEMS.values,
                VALUE_ITEM:DATA.ITEMS.ITEMS.value,   

                table_actions:[ 
                    {type:"btn",action:"manage_items",color:"secondary", outlined:false,  text:"Manage Items"},  
                ] 
            }
        },
        created() {
            try {
                // this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                console.log(this.sstate,'sstate...');
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items["ARCHIVED_"+(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join["ARCHIVED_"+(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                TaxGroups: state=> state.app[(DATA.ITEMS.TAX_GROUPS.values).toUpperCase()],  
            }),  
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            route(){
                return this.$route
            },
             
            /////////  
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("archive",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"Barcode",value:"code_",align:"center",show:false},      
                    {id:0,name:"Name",show:true}, 
                    {id:0,name:"Category",value:"category_name",show:true}, 
                    {id:0,name:"Brand",value:"brand_name",show:false}, 
                    {id:0,name:"Manufacturers",value:"manufacturer_name",show:false}, 
                    {id:0,name:"Wholesale Price",value:"wholesale_price",show:false}, 
                    {id:0,name:"Retail Price",value:"retail_price_",show:false}, 
                    {id:0,name:"Ordered",value:"total_received_stock_quantity",align:"center",show:false}, 
                    {id:0,name:"Return",value:"total_returned_stock_quantity",align:"center",show:false}, 
                    {id:0,name:"Adjustments",value:"total_adjust_stock_quantity",align:"center",show:false}, 
                    {id:0,name:"Return Sales",value:"total_return_sold_stock_quantity",align:"center",show:false}, 
                    {id:0,name:"Sales",value:"total_sold_quantity",align:"center",show:true}, 
                    {id:0,name:"Quantity",value:"total_quantity",align:"center",show:false}, 
                    {id:0,name:"Average Cost",value:"average_cost_price_",align:"right",show:true}, 
                    {id:0,name:"Total Cost",value:"total_cost_price_",align:"right",show:true}, 
                    {id:0,name:"Tax (16.5)",value:"tax_",align:"center",show:true},  
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Unarchive "+this.NAME_ITEM,action:"unarchive_item",icon:"delete_forever"}   
                    ]}, 
                ]
            }, 
            DataItems(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                let CalculatedItems = this.CalculatedItems
                return JoinedItems?JoinedItems:Items

                console.log(Items,JoinedItems,CalculatedItems,'......');
                if (CalculatedItems) {
                    return CalculatedItems
                }else if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            } 
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    let link = "/stock/items"
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "manage_items") {
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
        },
        watch: {
             
        },
    }
</script>
 
